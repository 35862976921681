<template>

	<div style="padding: 20px;text-align: center;">
		<!-- <span id="LoginWithAmazon" @click="LoginWithAmazon">
			<img border="0" alt="Login with Amazon" src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png" width="156" height="32" />
		</span>
		<div id="amazon-root"></div> -->

		<div style="padding: 20px 10px; font-size: 16px;">

			<div class="step-con">
				<div class="step-title">STEP1</div>

				<div class="step-msg">授权页面...</div>

				<!-- <div class="step-loading-icon" style=""><i class="el-icon-loading"></i></div> -->

				<div class="auth-icon-con">
					<div class="auth-icon-az">
						<img src="../../assets/images/ups/UPS_logo.svg" width="50" height="auto" />
					</div>
					<div class="auth-icon-arrow">
						<img src="../../assets/images/amazon/arrow-bothway.png" width="30" height="auto" />
					</div>
					<div class="auth-icon-hytx">
						<img src="../../assets/logo_en.png" width="150" height="auto" />
					</div>
				</div>
				
				

				<!-- <a target="_blank" :href="authSrc">{{$t('i18nn_907aa9302190b8b6')}}</a> -->
			</div>
			<div style="padding: 20px;" v-loading="loading">
				

				<div style="margin-bottom: 20px;">
					<el-button type="primary" @click="toAuth()" icon="el-icon-right">{{$t('i18nn_4b1ce14cae162e13')}}</el-button>
				</div>
				
				<div style="margin-bottom: 10px;">
					
					<div style="color: #999; margin-bottom: 10px;">{{$t('i18nn_2ed49c526ba70768')}}</div>
					
					<el-button type="primary" plain @click="toAuthValidate()">第一步(验证)</el-button>
				
					<el-button type="primary" plain @click="testAuthBack()">第二步(回调获取CODE)</el-button>
				</div>
				
				<!-- <div style="font-size: 12px;">
					<span>Back URL</span>:
					<span>{{redirect_uri}}</span>
				</div> -->

				<!-- <div style="margin-bottom: 10px;">
					ClientID：{{ClientID}}
				</div> -->
			</div>
		</div>
	</div>


</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				// ClientID: "lOOYRLaEgkDizg1G7rAmbNGMmRN2ixyaw3PuHhNboAGfq3Pp",
				ClientID: "",
				// redirect_uri: "https://www.baidu.com",
				redirect_uri: "https://warehouse.hytx.com/AuthFrame/UpsOauthBack",
			}
		},
		created() {

		},
		mounted() {
			this.getUpsAuthKey();
		},
		methods: {
			toAuthValidate() {
				let ClientID = this.ClientID;
				let redirect_uri = encodeURIComponent(this.redirect_uri);
				// window.location.href=`https://wwwcie.ups.com/security/v1/oauth/validate-client?client_id=${testClientID}&redirect_uri=${redirect_uri}`;
				let baseUrl = "https://onlinetools.ups.com";
				// let baseUrl = "https://wwwcie.ups.com/security";
				let url =
					`${baseUrl}?client_id=${ClientID}&redirect_uri=${redirect_uri}`;

				// let url = `/upsCie/security/v1/oauth/validate-client?client_id=${ClientID}&redirect_uri=${redirect_uri}`;
				// window.location.href = url;
				window.open(url,"_blank"); 
			},
			toAuth() {
				let ClientID = this.ClientID;
				let redirect_uri = encodeURIComponent(this.redirect_uri);
				// window.location.href=`https://wwwcie.ups.com/security/v1/oauth/validate-client?client_id=${testClientID}&redirect_uri=${redirect_uri}`;

				// let url =
				// 	`https://wwwcie.ups.com/security/v1/oauth/validate-client?client_id=${ClientID}&redirect_uri=${redirect_uri}`;

				let url =
					`/upsCie/security/v1/oauth/validate-client?client_id=${ClientID}&redirect_uri=${redirect_uri}`;
				// window.location.href = url;

				// return;

				this.loading = true;

				this.$http
					.get(url, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_776496ec446f21f9'), data);
						// console.log(data);
						
						this.loading = false;
						if ('success' == data.result) {
							// callback(data);

							this.authBack(data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('i18nn_6b2f2af88c6e0080');
							}
							this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						// console.log(error);
						console.log(this.$t('tips.errorData'), error);
						this.loading = false;
						this.$alert('授权失败！', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});

			},

			testAuthBack() {
				this.authBack({
					type: "ups_com_api"
				});
			},
			authBack(data) {
				// return;
				this.loading = true;
				// https://www.ups.com/lasso/signin?client_id=testClientID&redirect_uri=https://testapplication.com&response_type=code&scope=read&type=ups_com_api
				let client_id = this.ClientID;
				let redirect_uri = encodeURIComponent(this.redirect_uri);
				let response_type = "code";
				let scope = "read";
				let type = data.type;
				let RedirecUrl = data.LassoRedirectURL ? data.LassoRedirectURL : 'https://www.ups.com/lasso/signin';
				// window.location.href =
				// 	`https://www.ups.com/lasso/signin?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&type=${type}`;
				window.location.href =
					`${RedirecUrl}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&type=${type}`;
				this.loading = false;
			},
			//获取Upskey
			getUpsAuthKey() {
				this.loading = true;

				this.$http
					.get(this.$urlConfig.upsAuthGetKey, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_776496ec446f21f9'), data);
						// console.log(data);
						
						this.loading = false;
						if ('200' == data.code) {
							this.ClientID = data.data.clientId;
						} else {
							if (!data.msg) {
								data.msg = '获取key失败';
							}
							this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						// console.log(error);
						console.log(this.$t('tips.errorData'), error);
						this.loading = false;
						this.$alert('获取key失败！', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},
		}
	}
</script>

<style lang="less" scoped>
	.step-con {
		line-height: 150%;
	}
	.step-title {
		font-size: 32px; 
		padding: 10px 0;
	}
	.step-loading-icon {
		font-size: 24px; 
		padding: 10px 0;
	}
	.step-msg {
		font-size: 18px; 
		padding: 10px 0;
	}
	.auth-icon-con {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		.auth-icon-az {
			padding: 0 20px;
		}
		.auth-icon-arrow {
			padding: 0 20px;
		}
		.auth-icon-hytx {
			padding: 0 20px;
		}
	}
</style>
